<template>
  <div class="add-notice">
    <transition-group name="fadeUp">
      <div
        v-for="(message, index) in messages"
        :key="message.key"
        :ref="`notification-${message.key}`"
        :class="'alert-' + typesObject[message.type]"
        class="alert alert-dismissible fade in"
      >
        <button
          type="button"
          class="close"
          @click="dismissNotice(index)"
        >
          <span>&times;</span>
          <span class="sr-only" />
        </button>

        <span v-html="message.text" />
      </div>
    </transition-group>
  </div>
</template>

<script>
// utils
import generateRandomString from '@/utils/get_random_string_fn.js'
import getCoords from '@/utils/get_coords.js'

const generateKey = (notice) => `${notice.type}-${generateRandomString(10)}`

// maximum of messages on screen
const MAX_MESSAGES = 3

export default {
  name: 'FlashNotices',
  props: {
    initMessages: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      messages: this.initMessages.map((message) => {
        return {
          ...message,
          key: generateKey(message)
        }
      }),
      typesObject: {
        error: 'danger',
        alert: 'warning',
        notice: 'info'
      },
      parentTop: null
    }
  },
  watch: {
    messages (newVal, oldVal) {
      oldVal = oldVal || []

      if (oldVal.length === 0 && newVal.length > 0) {
        document.addEventListener('scroll', this.detectFixing, {
          passive: true
        })
        this.detectFixing()
      } else if (newVal.length === 0) {
        document.removeEventListener('scroll', this.detectFixing)
      }
    }
  },
  mounted () {
    this.parentTop = getCoords(this.$el.parentNode).top

    if (this.messages.length > 0) {
      document.addEventListener('scroll', this.detectFixing, {
        passive: true
      })
      this.detectFixing()
    }
  },
  methods: {
    detectFixing () {
      const scrolled = window.pageYOffset

      if (scrolled > this.parentTop) {
        this.$el.style.position = 'fixed'
      } else {
        this.$el.style.position = ''
      }
    },
    addNotice (notice) {
      // try to find duplicate error
      const duplicate = this.messages.find(({ text }) => text === notice.text)

      const addNotice = () => {
        const messages = [...this.messages]
        messages.push(newNotice)
        this.messages = messages
      }

      // pulsate if found duplication
      if (duplicate) {
        const styles = this.$refs[`notification-${duplicate.key}`][0].classList
        styles.add('pulsation')
        setTimeout(() => styles.remove('pulsation'), 600)
        return
      }

      const newNotice = {
        ...notice,
        key: generateKey(notice)
      }

      if (this.messages.length + 1 > MAX_MESSAGES) {
        this.dismissNotice(0)
        setTimeout(() => {
          addNotice()
        }, 250)
      } else {
        addNotice()
      }
    },
    dismissNotice (index) {
      const messages = [...this.messages]
      messages.splice(index, 1)
      this.messages = messages
    },
    clearNotices () {
      this.messages = []
    },
    error (text) {
      this.addNotice({
        type: 'error',
        text
      })
    },
    alert (text) {
      this.addNotice({
        type: 'alert',
        text
      })
    },
    notice (text) {
      this.addNotice({
        type: 'notice',
        text
      })
    }
  }
}
</script>

<style lang="css" scoped>
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeUp-enter-active,
.fadeInUp,
.fadeUp-leave-active,
.fadeOutUp {
  animation-duration: .25s;
  animation-fill-mode: both;
}

.fadeUp-enter-active,
.fadeInUp {
  animation-name: fadeInUp;
}

.fadeUp-leave-active,
.fadeOutUp {
  animation-name: fadeOutUp;
}

.pulsation {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: .6s;
  animation-duration: .6s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
</style>
