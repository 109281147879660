
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

const meta = document.querySelector('meta[name="logger-dsn"]')
const dsn = meta
  ? meta.content
  : null

Sentry.init({
  dsn,
  integrations: [new VueIntegration({
    Vue,
    attachProps: false,
    logErrors: true
  })]
})

const BrowserClient = new Sentry.BrowserClient({ dsn })

export default Sentry.Hub.bind(this, BrowserClient)
