<template>
  <div
    v-show="loading"
    :class="style"
    class="spinner text-center"
  >
    <div class="spinner__content">
      <slot :message="message">
        <i class="fa fa-fw fa-refresh fa-spin" />

        <span v-if="showText">
          {{ message }}
        </span>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Loading...'
    },
    loading: {
      type: Boolean,
      required: true
    },
    showText: {
      type: Boolean,
      default: true
    },
    relative: {
      type: Boolean,
      default: false
    },
    transparentBackground: {
      type: Boolean,
      default: false
    },
    darkOverlay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style () {
      return {
        'spinner--dark-overlay': this.darkOverlay,
        'spinner--transparent': this.transparentBackground,
        'spinner--relative': this.relative
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  &, &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &:before {
    content: '';
    display: block;
    background-color: #000;
    opacity: .1;
    z-index: 5;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    box-shadow: 0 0 10px 4px #fff;
    padding: .1em;
    transform: translate(-50%, -50%);
    z-index: 6;
  }

  &--relative {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;

    .spinner__content {
      position: relative;
      transform: none;
      left: auto;
      top: auto;
    }
  }

  &--transparent {
    .spinner__content {
      background-color: transparent;
      box-shadow: none;
    }

    &:before {
      opacity: 0;
    }
  }

  &--dark-overlay {
    .spinner__content {
      background-color: transparent;
      box-shadow: none;
      color: #fff;
    }

    &:before {
      opacity: .7;
    }
  }
}
</style>
