<template>
  <div class="form-group">
    <template v-if="!isVertical">
      <div
        v-if="label"
        class="col-sm-2 text-right"
      >
        <label
          :for="id"
          class="control-label"
        >
          {{ label }}
        </label>
      </div>

      <div
        class="col-sm-10"
        :class="{'col-sm-offset-2': !label}"
      >
        <div class="input-group">
          <input
            :id="id"
            class="form-control"
            :class="inputClass"
            :value="password"
            :type="type"
            :placeholder="placeholder"
            :disabled="readOnly"
            :autofocus="autofocus"
            :autocomplete="autocomplete"
            :name="name"
            @blur="$emit('blur')"
            @input="inputHandler($event.target.value)"
          >

          <div class="input-group-btn">
            <button
              type="button"
              tabindex="-1"
              class="btn btn-default"
              @click="trigger"
            >
              <i
                class="fa fa-fw"
                :class="iconStyle"
              />
            </button>
          </div>
        </div>

        <slot />
      </div>
    </template>

    <template v-if="isVertical">
      <label
        v-if="label"
        :for="id"
        class="control-label"
      >
        {{ label }}
      </label>

      <div class="input-group">
        <input
          :id="id"
          class="form-control"
          :class="inputClass"
          :value="password"
          :type="type"
          :disabled="readOnly"
          :autofocus="autofocus"
          :autocomplete="autocomplete"
          :placeholder="placeholder"
          :name="name"
          @blur="$emit('blur')"
          @input="inputHandler($event.target.value)"
        >

        <div class="input-group-btn">
          <button
            type="button"
            tabindex="-1"
            class="btn btn-default"
            @click="trigger"
          >
            <i
              class="fa fa-fw"
              :class="iconStyle"
            />
          </button>
        </div>
      </div>

      <slot />
    </template>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'password',
    event: 'input'
  },
  props: {
    password: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isVertical: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: Object,
      default () {
        return {}
      }
    },
    autocomplete: {
      type: String,
      default: 'on'
    }
  },
  data () {
    return {
      showText: false
    }
  },
  computed: {
    type () {
      return this.showText ? 'text' : 'password'
    },
    iconStyle () {
      return this.showText ? 'fa-eye' : 'fa-eye-slash'
    }
  },
  methods: {
    trigger () {
      this.showText = !this.showText
    },
    inputHandler (value) {
      this.$emit('input', value.trim())
    }
  }
}
</script>

<style lang="css" scoped>
.form-control[disabled] {
  cursor: copy !important;
}
</style>
