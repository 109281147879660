import LogIn from './LogIn.vue'
import initFlashNotices from '@/modules/layout/init_flash_notices_fn.js'

function init () {
  const {
    dataset: {
      createTeamPath,
      forgotPasswordPath
    }
  } = document.getElementById('root')

  const props = {
    createTeamPath,
    forgotPasswordPath
  }

  const app = new Vue({
    render (h) {
      return <LogIn {...{ props }} />
    }
  })

  app.$mount('#root')
}

initFlashNotices()
init()
