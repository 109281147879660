<template>
  <input
    type="hidden"
    name="authenticity_token"
    :value="token"
  >
</template>

<script>
export default {
  data () {
    return {
      token: document.querySelector('meta[name="csrf-token"]').content
    }
  }
}
</script>
