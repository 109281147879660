
Vue.config.productionTip = false

const FORMATS = ['json', 'flag', 'number']

class ModuleInitializer {
  constructor (component, props = {}, stringId = 'root') {
    this.stringId = stringId
    this.selector = `#${this.stringId}`
    this.props = props
    return this.mount(Vue.extend(component))
  }

  get element () {
    return document.getElementById(this.stringId)
  }

  get dataset () {
    if (Object.is({}, this.props)) return {}

    const dataset = {}

    // generating primary dataset...
    for (const propName in this.props) {
      const item = this.props[propName]

      // check name option to set prop name manually
      const name = item.name || propName

      // check value option to set prop value manually
      if (item.value) {
        dataset[name] = item.value
        continue
      }

      // if there is no such prop in dataset
      const datasetValue = this.element.dataset[propName]
      if (!datasetValue) continue

      // if property is boolean it means that it is presented
      // as string data-attribute
      if (typeof item === 'boolean') {
        dataset[propName] = datasetValue
      }

      // check if format is not expected
      if (item.format && !FORMATS.includes(item.format)) {
        throw new Error(`${propName} format is not supported!`)
      }

      // check format option to parse value properly
      const isJson = item.format === 'json' // use JSON.parse
      const isFlag = item.format === 'flag' // use !!parseInt to get a boolean from an integer
      const isNumber = item.format === 'number' // use parseInt to get an integer from a string value

      let value = datasetValue // use simple dataset value by default

      if (isJson) value = JSON.parse(datasetValue)
      if (isFlag) value = !!parseInt(datasetValue)
      if (isNumber) value = parseInt(datasetValue)

      dataset[name] = value
    }

    return dataset
  }

  mount (Component) {
    return new Component({
      el: this.selector,
      propsData: this.dataset
    })
  }
}

export default ModuleInitializer
