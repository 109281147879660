function getCoords (elem) {
  const box = elem.getBoundingClientRect()

  return {
    top: box.top + pageYOffset,
    left: box.left + pageXOffset,
    bounds: box
  }
}

export default getCoords
