<template>
  <div>
    <h1 class="text-center">
      Sign in

      <br>

      <small>
        {{ host }}
      </small>
    </h1>

    <p>
      Enter your email address and password.
    </p>

    <br>

    <form
      ref="form"
      class="login-form"
      novalidate="true"
      action="/login"
      method="post"
      @submit.prevent="logIn"
    >
      <token />

      <div
        class="form-group"
        :class="{'has-error': touched['email'] && emailValidationError}"
      >
        <input
          v-model="credentials.email"
          type="email"
          class="form-control"
          placeholder="Enter email"
          autofocus
          autocorrect="none"
          autocapitalize="none"
          name="user[email]"
          @blur="touch('email')"
        >

        <small
          v-show="touched['email'] && emailValidationError"
          class="help-block"
        >
          <span class="text-danger">{{ emailValidationError }}</span>
        </small>
      </div>

      <!-- password -->
      <password
        id="password"
        v-model="credentials.password"
        :class="{'has-error': touched['password'] && passwordValidationError}"
        name="user[password]"
        placeholder="Enter password"
        :is-vertical="true"
        @blur="touch('password')"
      >
        <!-- validation message -->
        <small
          v-show="touched['password'] && passwordValidationError"
          class="help-block"
        >
          <span class="text-danger">{{ passwordValidationError }}</span>
        </small>
      </password>

      <!-- remember me -->
      <div class="form-group clearfix">
        <label class="checkbox-inline pull-left">
          <input
            id="remember"
            v-model="credentials.rememberMe"
            type="checkbox"
            name="user[remember_me]"
            value="1"
          >

          Remember me
        </label>

        <a
          :href="forgotPasswordPath"
          class="pull-right"
          title="Reset password"
        >Forgot password?</a>
      </div>

      <button
        type="submit"
        class="btn btn-primary btn-block relative"
        :disabled="submitDisabled"
      >
        <spinner
          :loading="submitDisabled"
          :show-text="false"
          :transparent-background="true"
        />

        <span :class="{ 'hidden-text': submitDisabled }">
          Sign in
        </span>
      </button>
    </form>

    <br>

    <p>
      Don't have an account on this team yet?<br>
      Contact the team administrator for an invitation!
    </p>

    <br>

    <p>
      Trying to create a team?
      <a
        :href="createTeamPath"
        title="Create your team"
      >So let's start!</a>
    </p>
  </div>
</template>

<script>
// utils
import emailIsValid from '@/utils/email_validation_fn.js'

// components
import Spinner from '@/components/Spinner.vue'
import Password from '@/components/PasswordInput.vue'
import Token from '@/components/CSRFToken.vue'

export default {
  components: {
    Spinner,
    Password,
    Token
  },
  props: {
    createTeamPath: {
      type: String,
      required: true
    },
    forgotPasswordPath: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      submitDisabled: false,
      host: window.location.host,
      credentials: {
        email: '',
        password: '',
        rememberMe: false
      },
      touched: {}
    }
  },
  computed: {
    emailValidationError () {
      if (!this.credentials.email) {
        return 'Enter email!'
      }

      if (!emailIsValid(this.credentials.email)) {
        return 'Email is invalid!'
      }

      return ''
    },
    passwordValidationError () {
      if (!this.credentials.password) {
        return 'Password is required!'
      }

      return ''
    },
    formInvalid () {
      return !!this.emailValidationError || !!this.passwordValidationError
    }
  },
  methods: {
    touch (key) {
      this.$set(this.touched, key, true)
    },
    logIn () {
      if (this.formInvalid) {
        this.touched = {
          email: true,
          password: true
        }

        return
      }
      this.submitDisabled = true
      this.$refs.form.submit()
    }
  }
}
</script>

<style lang="scss" src="@/styles/registration.scss"></style>
