import Module from '@/services/page_module_initializer'
import FlashNotices from '@/components/FlashNotices.vue'

// init flash notices
function initFlashNotices () {
  window.layoutComponents = window.layoutComponents || {}

  window.layoutComponents.flashNotices = new Module(
    FlashNotices,
    {
      messages: {
        name: 'initMessages',
        format: 'json'
      }
    },
    'flash-notices'
  )
}

export default initFlashNotices
